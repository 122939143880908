import React from 'react'
import ReactDom from 'react-dom'


//CSS
import './index.css'

//Setup variables
const books = [
  {
    id: 1,
    img: "https://images.unsplash.com/photo-1472214103451-9374bd1c798e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGljfGVufDB8fDB8fHww",
    title: 'I Love You To the Moon and Back',
    author: 'Namus Name'
  },

  {
    id: 2,
    img: "https://images.unsplash.com/photo-1472214103451-9374bd1c798e?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8cGljfGVufDB8fDB8fHww",
    title: 'I Love You To the Moon and Back',
    author: 'Namus Nimbus'
  },
]


function BookList(){
  return (
      //Iterating over books array and returning each object
      <section className= 'booklist'>
        {books.map((book) => {
          return (
          <Book 
            key={book.id}
            book={book}>
            
          </Book>
          ); //props book takes in book variable
        })}
      </section>
    );
}

const Book = (props) => {
  const{ img, title, author } = props.book;
  const clickHandler = () =>{
    alert('Hello World');
  };
  const complexExample = (a) => {
    console.log(a);
  }
  return (
  <article className= 'book'> 
    <img 
      src={img} 
      alt="" />
    <h1 onClick={() => complexExample(title)}>{title}</h1>
    <h4 >{author}</h4>
    <button type="button" onClick={clickHandler}>reference example</button>
  </article>
    );
}


ReactDom.render(<BookList/>, document.getElementById('root'));
